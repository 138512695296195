import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styled from "styled-components"

const StyledRow = styled.div`
   margin-left: 0px;
   margin-right: 0px;
`

export const Column = ({ children, className }) => {
   const columnClasses = classNames({ "column": true, [className]: className})
   return <div className={columnClasses}>{children}</div>
}

export const Row = ({ children, className }) => {
   const rowClasses = classNames({ "columns": true, [className]: className})
   return <StyledRow className={rowClasses}>{children}</StyledRow>
}