import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Link, animateScroll as scroll } from "react-scroll";
import Img from "gatsby-image";
import styled, { css } from "styled-components";
import classNames from "classnames";

import { Row, Column } from "./bulma-components";

const ActiveLink = css`
  border-top: 2px solid #063c68;
  padding-top: 0.4rem;
`;
const NavLayout = css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;

const Nav = styled.nav`
  ${NavLayout}
  & .navbar-menu {
    @media (min-width: 1024px) {
      ${NavLayout}
    }
  }
`;

const NavLink = styled(Link)`
  margin-right: 1rem;
  text-transform: uppercase;
  color: #063c68;
  font-weight: 500;
  padding: 0.55rem;
  ${props => props.active && ActiveLink}
`;

const Header = styled.header`
  padding: 0.75rem;
  border-bottom: 3px solid #063c68;
  @media (min-width: 1024px) {
    position: sticky;
    width: 100%;
    padding-top: 1rem;
    top: 0;
    background: white;
    z-index: 2;
  }
  @media (max-width: 1024px) {
    position: relative;
  }
`;

const MobileNav = styled(Column)`
  padding: ${props => (props.mobile ? "0.5rem 0rem 0rem 0rem" : "0")};
`;

const Navigation = () => {
  const [activeItem, setActiveItem] = useState("Home");
  const [mobile, setMobile] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 250, height: 55) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const mobileClass = classNames({
    "is-active": mobile,
    "navbar-menu": true
  });

  return (
    <Header>
      <Row className="is-multiline is-mobile">
        <Column className="is-half-tablet is-three-quarters-mobile">
          <Img fixed={data.placeholderImage.childImageSharp.fixed} alt="Bycroft Consulting Logo" />
        </Column>
        <Column className="is-half-tablet is-quarter-mobile">
          <Nav
            className="navbar"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="navbar-brand">
              <a
                role="button"
                className="navbar-burger"
                aria-label="menu"
                aria-expanded="false"
                onClick={e => setMobile(!mobile)}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div className="navbar-menu">
              <NavLink
                className="navbar-item"
                to="home"
                smooth={true}
                duration={500}
                spy={true}
                onClick={e => setActiveItem("Home")}
                onSetActive={e => setActiveItem("Home")}
                active={activeItem === "Home"}
              >
                Home
              </NavLink>
              <NavLink
                className="navbar-item"
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                offset={-85}
                onClick={e => setActiveItem("About")}
                onSetActive={e => setActiveItem("About")}
                active={activeItem === "About"}
              >
                About Us
              </NavLink>
              <NavLink
                className="navbar-item"
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                offset={-85}
                onClick={e => setActiveItem("Services")}
                onSetActive={e => setActiveItem("Services")}
                active={activeItem === "Services"}
              >
                What We Do
              </NavLink>
              <NavLink
                className="navbar-item"
                to="contact"
                smooth={true}
                duration={500}
                spy={true}
                offset={-85}
                onClick={e => setActiveItem("Contact")}
                onSetActive={e => setActiveItem("Contact")}
                active={activeItem === "Contact"}
              >
                Contact
              </NavLink>
            </div>
          </Nav>
        </Column>
        <MobileNav
          className="is-full-mobile is-hidden-tablet is-hidden-desktop"
          mobile={mobile}
        >
          <div className={mobileClass}>
            <Link
              className="navbar-item"
              to="home"
              smooth={true}
              duration={500}
              spy={true}
              >
              Home
            </Link>
            <Link
              className="navbar-item"
              to="about"
              smooth={true}
              duration={500}
              spy={true}
            >
              About Us
            </Link>
            <Link
              className="navbar-item"
              to="services"
              smooth={true}
              duration={500}
              spy={true}
            >
              What We Do
            </Link>
            <Link
              className="navbar-item"
              to="contact"
              smooth={true}
              duration={500}
              spy={true}
            >
              Contact
            </Link>
          </div>
        </MobileNav>
      </Row>
    </Header>
  );
};

export default Navigation;
